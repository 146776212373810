/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Add zero before number
  function minTwoDigits(n) {
    return (n < 10 ? '0' : '') + n;
  }

  var heroBgLoad = function () {

    // Show BG once loaded to prevent flicker
    var hero = $('.hero-bg .bg');

    if (hero.length) {

      var bg = hero.css('backgroundImage'),
        src = bg.replace(/(^url\()|(\)$|[\"\'])/g, '');

      $('<img/>').attr('src', src).on('load', function () {
        hero.addClass('loaded');
      });

    }

  };
  // -------------------

  // AOS - Scroll Animations
  var aos = function () {
    AOS.init({
      duration: 800,
      disable: function () {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);
      }
    });

  };
  // -------------------

  // Count Up
  var countUp = function () {

    var countUp = $('.js-count-up');

    if (countUp.length) {

      var waypoints = $('.number-satisfaction').waypoint(function (direction) {

        $.each(countUp, function () {

          var options = {
            useEasing: false
          };

          var count = $(this).data('count'),
            numAnim = new CountUp(this, 0, count, 0, 2.5, options);

          numAnim.start();

          waypoints[0].destroy(); //stop

        });

        $('.number-satisfaction li').each(function () {

          var circle = $(this).find('.circle'),
            data = circle.prev('.number').find('.js-count-up');

          // IE bugs out if over 6 strokewidth
          if (Object.hasOwnProperty.call(window, "ActiveXObject") && !window.ActiveXObject) {
            var stroke = 6,
              trial = 6;
            $(this).addClass('ie-border');
          } else {
            var stroke = 10,
              trial = 10;
          }
          var bar = new ProgressBar.Circle(circle[0], {
            strokeWidth: stroke,
            easing: 'easeInOut',
            duration: 2500,
            color: '#005eb8',
            trailColor: '#DFE2E5',
            trailWidth: trial,
            svgStyle: null
          });

          numCount = data.attr('data-count');

          // Divide percentage/fractions
          if (data.hasClass('percent')) {
            if (numCount > 95) { // If higher than 95 take a bit more off to show bigger gap
              var num = numCount / 100 - 0.03;
            } else {
              var num = numCount / 100;
            }
          }
          if (data.hasClass('fraction')) {
            var num = numCount / 10;
          }
          if (data.hasClass('large')) {
            if (numCount < 100) { // If under 100 divide by 100 to get correct number, else just show it full (1.0)
              var num = numCount / 100;
            } else {
              var num = 1.0;
            }
          }

          bar.animate(num);  // Number from 0.0 to 1.0
          circle.addClass('active'); // animate color

          waypoints[0].destroy(); //stop

        });

      }, {

        offset: '70%'

      });

    }

  };
  // -------------------

  // Navigation
  var navigation = function () {
    var menu = $('.mobile-menu'),
      nav = $('.primary-main');
    navItems = $('.primary-main .nav'),
      info = $('header.primary .info');

    menu.on('click', function () {

      nav.toggleClass('active-menu');
      navItems.toggleClass('active-menu');
      info.toggleClass('active-menu');

    });

    // Nav Sub Menu
    var dropdown = $('.menu-item.menu-item-has-children');

    // \Dropdown
    if (window.matchMedia("(min-width: 992px)").matches) {

      var personaDrop = $('.persona-dropdown');
      dropdown.on('click', function (e) {

        e.preventDefault();

        if ($(this).hasClass("active-menu")) {
          personaDrop.removeClass('active-menu');
          dropdown.removeClass('active-menu');
        } else {
          personaDrop.addClass('active-menu');
          $(this).addClass('active-menu');
        }
      });

    } else {

      var dropMobile = dropdown.find('a:first').addClass('drop-mobile');

      // Mobile Dropdown
      dropMobile.on('click', function (e) {
        e.preventDefault();

        $(this).toggleClass('active-menu');
        $(this).next('.sub-menu').fadeToggle();

      });

    }

  };
  // -------------------

  // Navigation
  var heroPersonaSelect = function () {

    // Select
    var select = $('.hero-persona-select');

    if (select.length) {

      // IE 11 fix
      if (Object.hasOwnProperty.call(window, "ActiveXObject") && !window.ActiveXObject) {
        $('.persona-select').css({
          opacity: 1,
          transform: 'translateY(0)'
        });
      }

      // Select2 options
      select.select2({
        placeholder: 'Select',
        minimumResultsForSearch: Infinity,
        theme: 'chase',
        width: 'style',
        dropdownPosition: 'below'
      });

      // Custom Scroll Bar
      select.on("select2:open", function (e) {
        //  bind niceScroll to dropdowns
        $(".select2-results__options").niceScroll({
          cursorborder: 'none', // optional
          background: 'rgba(255, 255, 255, 1)',
          cursorcolor: '#005EB8',
          railoffset: {top: 0, left: -30}
        });
      });

      select.on('select2:closing', function () {
        $('.select2-results__options').getNiceScroll().remove();
      });

      // On Change go to link
      select.on('change', function () {

        var link = $("option:selected", this).attr('data-link');

        location.href = link;

      });

    }

  };
  // -------------------

  var blockService = function () {

    var service = $('.service-selection');

    if (service.length) {

      var list = $('.selection-list .list'),
        items = $('.selection-list .list .list-item');

      items.on('click', function () {

        // Add/Remove Active Link on list items
        items.each(function () {
          $(this).removeClass('active');
        });
        $(this).addClass('active');

        // Show content on matching id
        var content = $('.selection-content .content'),
          data = $(this).attr('data-content');

        content.each(function () {

          var id = $(this).attr('id');

          // If id/data matches then show
          if (data == id) {

            $(this).delay(500).fadeIn(400, function () {
              $(this).addClass('active');
            });

            // else hide
          } else {

            $(this).fadeOut(400, function () {
              $(this).removeClass('active');
            });

          }

        });

      });

    }

  };
  // -------------------

  var slickEvents = function () {

    var eventsSlider = $('.events-slider'),
      eventsCount = $('.events-wrap .events-count');

    if (eventsSlider.length) {

      // get slider count
      eventsSlider.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
        var i = (currentSlide ? currentSlide : 0) + 1;
        eventsCount.find('.current').text(minTwoDigits(i));
        eventsCount.find('.total').text('/' + minTwoDigits(slick.slideCount));
      });

      // Show the first slide once we get to the last one
      eventsSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {

        if (slick.$slides.length == currentSlide + slick.options.slidesToScroll + 1) {

          eventsSlider.find('.slick-slide:first').addClass('showlast');

        } else {

          eventsSlider.find('.slick-slide').each(function () {
            $(this).removeClass('showlast');
          });

        }
      });

      // Slick options
      eventsSlider.slick({
        mobileFirst: true,
        slidesToShow: 1,
        arrows: false,
        dots: true,
        fade: true,
        speed: 1000
      });

      // Arrows
      var eventsPrev = $('.events-nav .nav-prev'),
        eventsNext = $('.events-nav .nav-next');

      eventsPrev.on('click', function () {
        eventsSlider.slick('slickPrev');
      });
      eventsNext.on('click', function () {
        eventsSlider.slick('slickNext');
      });

    }

  };
  // -------------------

  var slickNews = function () {

    var newsSlider = $('.news-slider');

    if (newsSlider.length) {

      // Slick options
      newsSlider.slick({
        mobileFirst: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        fade: false,
        speed: 1000,
        variableWidth: true
      });

      // Arrows
      var newsPrev = $('.news-nav .nav-prev'),
        newsNext = $('.news-nav .nav-next');

      newsPrev.on('click', function () {
        newsSlider.slick('slickPrev');
      });
      newsNext.on('click', function () {
        newsSlider.slick('slickNext');
      });

    }

  };

  var slickNewsInsights = function () {

    var newsSlider = $('.js-news-insights-slider');

    if (newsSlider.length) {

      // Slick options
      newsSlider.slick({
        mobileFirst: true,
        arrows: false,
        dots: false,
        fade: false,
        speed: 1000,
        variableWidth: false,
        infinite: false,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              centerMode: false,
              infinite: false,
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
          {
            breakpoint: 991,
            settings: {
              infinite: true,
              centerMode: true,
              slidesToShow: 3,
              slidesToScroll: 3,
              centerPadding: '100px'
            }
          },
          {
            breakpoint: 767,
            settings: {
              infinite: true,
              centerMode: true,
              slidesToShow: 2,
              slidesToScroll: 2,
              centerPadding: '100px'
            }
          },
          {
            breakpoint: 0,
            settings: {
              infinite: true,
              centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });

      // Arrows
      var newsPrev = $('.news-insights-nav .nav-prev'),
        newsNext = $('.news-insights-nav .nav-next');

      newsPrev.on('click', function () {
        newsSlider.slick('slickPrev');
      });
      newsNext.on('click', function () {
        newsSlider.slick('slickNext');
      });

    }

  };

  var slickPortraitThumbs = function () {

    var newsSlider = $('.js-portrait-thumbs');

    if (newsSlider.length) {

      // Slick options
      newsSlider.slick({
        mobileFirst: true,
        arrows: false,
        dots: false,
        fade: false,
        speed: 1000,
        variableWidth: false,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              centerMode: false
            }
          },
          {
            breakpoint: 577,
            settings: {
              centerPadding: '100px',
              centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 0,
            settings: {
              centerPadding: '25px',
              centerMode: true,
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });

      // Arrows
      var newsPrev = $('.news-nav .nav-prev'),
        newsNext = $('.news-nav .nav-next');

      newsPrev.on('click', function () {
        newsSlider.slick('slickPrev');
      });
      newsNext.on('click', function () {
        newsSlider.slick('slickNext');
      });

    }

  };

  // -------------------

  var slickLocations = function () {

    var locationsSlider = $('.locations-carousel');

    if (locationsSlider.length) {

      // Slick options
      locationsSlider.slick({
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        fade: false,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 400,
        infinite: true,
        variableWidth: true,
        centerMode: true,
        prevArrow: '<button type="button" class="slick-prev"><span class="icon-prev"></span></button>',
        nextArrow: '<button type="button" class="slick-next"><span class="icon-next"></span></button>',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              variableWidth: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              variableWidth: false
            }
          }
        ]
      });

      // Arrows
      var newsPrev = $('.news-nav .nav-prev'),
        newsNext = $('.news-nav .nav-next');

      newsPrev.on('click', function () {
        locationsSlider.slick('slickPrev');
      });
      newsNext.on('click', function () {
        locationsSlider.slick('slickNext');
      });

    }

  };

  // -------------------

  var heroVideo = function () {

    var $hero = $('.hero.hero--video');

    var $videoContainer = $('.hero-video-container');

    if ($hero.length && $videoContainer.length) {

      var $play = $('.hero-video-play a'),

        iframe = $('iframe', $videoContainer);

      var player = new Vimeo.Player(iframe[0]);

      $play.on('click', function () {
        player.play();
      });

      player.on('play', function () {
        $hero.addClass('playing-video');
        $videoContainer.fadeIn();
      });

      player.on('pause', function () {

        $hero.removeClass('playing-video');

        $videoContainer.fadeOut();

      });

    }

  };

  // -------------------

  var locationsFilter = function () {

    var form = $('#location-category-select');
    var select = form.find('select[name="locations"]');

    select.on('change', function (e) {
      console.log('changed');
      form.submit();
    });

  };
  // -------------------

  // -------------------

  var portfolios = function () {
    $(document).on('click', '.priority_selector', function (e) {
      const priority = e.target.dataset.id;
      var risk = $('#selected-risk').val();

      if (!risk) {
        risk = $('.risk_selector.active').data('id');
      }

      $('#selected-priority').val(priority);
      $('.priority_selector').removeClass('active');
      $('.priority_selector.' + priority).addClass('active');
      $('.portfolio-section__main-body-priority-item').css('display', 'none');
      $('.portfolio-section__main-body-priority-item.' + priority).css('display', 'block');
      $('.portfolio-section__item').css('display', 'none');
      $('.portfolio-section__item.risk-' + risk + '.priority-' + priority).css('display', 'block');
    });

    $(document).on('click', '.risk_selector', function (e) {
      const risk = e.target.dataset.id;
      var priority = $('#selected-priority').val();

      if (!priority) {
        priority = $('.priority_selector.active').data('id');
      }

      $('#selected-risk').val(risk);
      $('.risk_selector').removeClass('active');
      $('.risk_selector.' + risk).addClass('active');
      $('.portfolio-section__main-body-risk-item').css('display', 'none');
      $('.portfolio-section__main-body-risk-item.' + risk).css('display', 'block');
      $('.portfolio-section__item').css('display', 'none');
      $('.portfolio-section__item.risk-' + risk + '.priority-' + priority).css('display', 'block');
    });
  };

  // -------------------

  var slickPortfolio = function () {
    $('.portfolio-section__main-body__select--priorities, .portfolio-section__main-body__select--risks')
      .slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1000,
        infinite: false,
        dots: false,
        arrows: false,
        mobileFirst: true,
        centreMode: true,
        responsive: [
          {
            breakpoint: 767,
            rows: 0,
            settings: 'unslick'
          }
        ]
      });
  };

  // -------------------

  var slickTeam = function () {
    var teamSlider = $('.team--dpm__carousel');
    teamSlider.slick({
      slidesToShow: 1.2,
      slidesToScroll: 1,
      speed: 1000,
      centerMode: true,
      infinite: false,
      mobileFirst: true,
      arrows: false,
      dots: false,
      fade: false,
      centerPadding: '60px',
      responsive: [
        {
          breakpoint: 769,
          settings: {
            slidesToShow: 2.2,
            centerMode: false
          }
        },
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 2.2,
            centerMode: false
          }
        }
      ]
    });

    // Arrows
    const $teamPrev = $('.team--dpm__nav .nav-prev');
    const $teamNext = $('.team--dpm__nav .nav-next');

    $teamPrev.on('click', function () {
      teamSlider.slick('slickPrev');
    });

    $teamNext.on('click', function () {
      teamSlider.slick('slickNext');
    });
  };

  var advisorsProfile = function () {

    var advisorList = $('.advisor-list');

    if (advisorList.length) {

      var listSecondary = $('.list-secondary li a'),
        profile = $('.advisor-selected');

      listSecondary.on('click', function () {

        // Add/remove current class
        listSecondary.each(function () {
          $(this).parent().removeClass('current');
        });
        $(this).parent().addClass('current');

        // Get Clicked on data
        var name = $(this).data('name'),
          pos = $(this).data('pos'),
          text = $(this).data('text'),
          img = $(this).data('img');

        // Get current profile data
        var profileName = $('.title', profile),
          profilePos = $('.position', profile),
          profileText = $('.copy', profile),
          profileImg = $('.profile', profile),
          profileElements = profile.find('.title, .position, .copy, .profile');

        // Animate out/In ahd change text
        profileElements.addClass('animate-out').delay(500).queue(function (next) {

          // Add classes for animating in/out
          $(this).removeClass('animate-out');
          $(this).addClass('animate-in');
          $(this).removeClass('animate-in');

          // Replace text with data attribrutes from click
          profileName.text(name);
          profilePos.text(pos);
          profileText.text(text);
          if (img) {
            profileImg.css({
              'background-image': 'url(' + img + ')',
              'display': ''
            });
          } else {
            profileImg.css('display', 'none');
          }

          next();
        });

      });

    }

  };
  // -------------------

  var advisorsList = function () {

    var advisorList = $('.advisor-list');

    if (advisorList.length) {

      var listPrimary = $('.list-primary li a'),
        listCollection = $('.list-collection .list-secondary');

      listPrimary.on('click', function () {

        // Add/remove current class
        listPrimary.each(function () {
          $(this).parent().removeClass('current');
        });
        $(this).parent().addClass('current');

        // Get data-list-show and later match with data-list
        var list = $(this).data('list-show');

        listCollection.each(function () {

          // Get data-list of each list
          var collection = $(this).data('list');

          // If data list attribrutes match then show and hide others
          if (collection === list) {

            $(this, collection).delay(500).fadeIn(400, function () {
              $(this).addClass('active');
            });

          } else {

            $(this, collection).fadeOut(400, function () {
              $(this).removeClass('active');
            });

          }

        });

      });

    }

  };
  // -------------------

  var advisorsListMobile = function () {

    var advisorList = $('.block-advisors-mobile ');

    if (advisorList.length) {

      // Open Menu
      var dropdown = $('.advisor-dropdown'),
        lists = $('.advisor-lists');

      dropdown.on('click', function () {
        lists.slideToggle();
      });

      // Slide down advisors for each location
      var location = $('.advisor-location');

      var advisor = $('.advisors .profile'),
        profile = $('.advisor-selected');

      location.on('click', function () {

        var advisorInfo = $(this).next('.advisor-info');

        location.each(function () {
          if ($(this).hasClass('active')) {
            $(this).next(advisorInfo).slideUp();
          }
        });

        $(this).addClass('active');
        advisorInfo.addClass('active');
        advisorInfo.slideToggle();
        profile.hide();

      });

      // On click of each advisor change text and scroll into view
      advisor.on('click', function () {

        // Get Clicked on data
        var name = $(this).data('name'),
          pos = $(this).data('pos'),
          text = $(this).data('text'),
          img = $(this).data('img');

        // Get current profile data
        var profileName = $('.title', profile),
          profilePos = $('.position', profile),
          profileText = $('.copy', profile),
          profileImg = $('.profile', profile),
          profileElements = profile.find('.title, .position, .copy, .profile');

        $(this).parent().parent().next(profile).fadeIn(function () {

          // Animate out/In ahd change text
          profileElements.addClass('animate-out').queue(function (next) {

            // Add classes for animating in/out
            $(this).removeClass('animate-out');
            $(this).addClass('animate-in');
            $(this).removeClass('animate-in');

            // Replace text with data attribrutes from click
            profileName.text(name);
            profilePos.text(pos);
            profileText.text(text);
            profileImg.css('background-image', 'url(' + img + ')');

            next();
          });

        });

      });

    }

  };
  // -------------------

  var slickTestimonial = function () {

    var testimonialSlider = $('.testimonial-slider');

    if (testimonialSlider.length) {

      // Slick options
      testimonialSlider.slick({
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        fade: true,
        speed: 1000
      });

      // Arrows
      var testimonialNav = $('.testimonial-nav'),
        testimonialPrev = $('.nav-prev', testimonialNav),
        testimonialNext = $('.nav-next', testimonialNav);

      testimonialNav.appendTo(testimonialSlider);

      testimonialPrev.on('click', function () {
        testimonialSlider.slick('slickPrev');
      });
      testimonialNext.on('click', function () {
        testimonialSlider.slick('slickNext');
      });

    }

  };
  // -------------------

  var videoEmbedPlay = function () {

    var embed = $('.embed-container');

    if (embed.length) {

      var iframe = $('iframe', embed),
        play = $('.embed-video-play a', embed),
        bg = $('.embed-wrap', embed);

      play.on('click', function () {

        bg.fadeOut();
        iframe[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}',
          '*');

      });

    }

  };
  // -------------------

  var arrangeATalkFormSelect = function () {
    var currentSubjects = "";
    $('.subject-wrapper .form-checkbox-wrapper ul li.active').each(function () {
      currentSubjects += $(this).find('span').html() + ", ";
    });
    $('#subject-input').val(currentSubjects);
  };

  // -------------------
  var arrangeATalkForm = function () {
    $('.subject-wrapper .form-checkbox-wrapper ul li').on('click', function () {
      if (!$(this).hasClass('other-subject-line')) {
        $(this).toggleClass('active');
        if ($(this).find('span').html() == 'Other') {
          if ($(this).hasClass('active')) {
            $('.other-subject-line').addClass('active');
          } else {
            $('.other-subject-line').removeClass('active');
          }
        }
        arrangeATalkFormSelect();
      }
    });
  };

  // -------------------
  var checkApplicationStep1 = function () {
    var location = $('.location-confirmation span').html();
    var date = $('.date-confirmation span').html();
    var time = $('.time-confirmation span').html();

    var validLocation = false;
    var validDate = false;
    var validTime = false;

    if (location == '' || location == undefined || location == null || location === 'Our Office') {
      $('.address-wrap .validation').show();
    } else {
      validLocation = true;
      $('.address-wrap .validation').hide();
    }
    if (date == '' || date == undefined || date == null) {
      $('#date-validation').show();
    } else {
      validDate = true;
      $('#date-validation').hide();
    }
    if (time == '' || time == undefined || time == null) {
      $('#time-validation').show();
    } else {
      validTime = true;
      $('#time-validation').hide();
    }

    if (validDate && validTime && validLocation) {
      return true;
    } else {
      $('html, body').animate({
        scrollTop: $(".steps-wrap").offset().top - 100
      }, 1000);
      return false;
    }
  };

  // -------------------
  var checkApplicationStep2 = function () {

    var radiocontactmethod = $("input:radio[name ='radio-contact-method']:checked").val();
    var radiograde = $("input:radio[name ='radio-grade']:checked").val();
    var advicerequests = $("input[name ='advice-requests']").val();

    var validContact = false;
    var validGrade = false;
    var validAdvice = false;

    if (radiocontactmethod ===
      '' ||
      radiocontactmethod ===
      undefined ||
      radiocontactmethod ===
      null) {
      $('#contact-medthod-validation').show();
    } else {
      $('#contact-medthod-validation').hide();
      validContact = true;
    }
    // if (radiograde == '' || radiograde == undefined || radiograde == null) {
    //     $('#grade-validation').show();
    // } else {
    //     $('#grade-validation').hide();
    //     var validGrade = true;
    // }
    if (advicerequests == '' || advicerequests == undefined || advicerequests == null) {
      $('#interests-validation').show();
    } else {
      $('#interests-validation').hide();
      validAdvice = true;
    }
    if (validAdvice && validContact) {
      return true;
    } else {
      return false;
    }

  };

  // -------------------
  var showNextApplicationStep = function (step) {
    $('.steps-wrap .active').removeClass('active');
    $('.step-1-col').hide();
    if (step == 2) {
      $('.step-2-col').show();
    } else {
      $('.step-2-col').hide();
      $('.step-3-col').show();
    }
    var stepIndex = step - 1;
    $('.steps-wrap .row .col-12').eq(stepIndex).addClass('active');

    $('html, body').animate({
      scrollTop: $(".steps-wrap").offset().top - 100
    }, 1000);

  };

  // -------------------

  var setLocationOffice = function () {
    var selectorValue = document.querySelector('select[name=office-selector]').value;
    $('.address-wrap .office-helper').addClass('active');
    $('.address-wrap .work-helper').removeClass('active');
    $('.address-wrap .home-helper').removeClass('active');

    if (selectorValue === 'Select Office') {
      $('.location-confirmation span').text('Our Office');
    } else {
      $('.location-confirmation span').text('Our Office - ' + selectorValue);
    }

    $('#location-target').hide();
    $('.address-wrapper').hide();
    $('.office-selector').show();
    $('.postcode-wrapper').show();
  };

  var setLocationHome = function () {
    $('.location-confirmation span').text('Your Home');
    $('.address-wrap .office-helper').removeClass('active');
    $('.address-wrap .work-helper').removeClass('active');
    $('.address-wrap .home-helper').addClass('active');
    $('#location-target').text('Please enter your home address').show();
    $('.address-wrapper').find('input').attr("disabled", false);
    $('.office-selector').hide();
    $('.address-wrapper').show();
    $('.postcode-wrapper').hide();
    $('select[name=office-selector]').val('Select Office');

    clearInputs('');
  };

  var setLocationWork = function () {
    $('.address-wrap .office-helper').removeClass('active');
    $('.address-wrap .work-helper').addClass('active');
    $('.address-wrap .home-helper').removeClass('active');
    $('.location-confirmation span').text('Your Work');
    $('#location-target').text('Please enter your work address').show();
    $('.address-wrapper').find('input').attr("disabled", false);
    $('.address-wrapper').show();
    $('.postcode-wrapper').hide();
    $('.office-selector').hide();
  };

  var clearInputs = function (input) {
    $("input[name='home-addr-line1']").val(input);
    $("input[name='home-addr-line2']").val(input);
    $("input[name='home-city']").val(input);
    $("input[name='home-county']").val(input);
    $("input[name='home-postcode']").val(input);
  };
  // -------------------

  var formatLocation = function (loc) {
    var str = "";

    if (loc == 'Your Work' || loc == 'Your Home') {
      str = $('input[name="home-addr-line1"]').val();
      if ($('input[name="home-addr-line2"]').val() != '') {
        str += ', ' + $('input[name="home-addr-line2"]').val();
      }
      str += ', ' + $('input[name="home-addr-line2"]').val();
      str += ', ' + $('input[name="home-city"]').val();
      str += ', ' + $('input[name="home-postcode"]').val();
    } else {
      str = 'Chase de Vere Office in: ' + $('select[name="office-selector"]').val();
    }
    return str;
  };

  var formSerialize = function () {

    $('#location-output').val(formatLocation($('.location-confirmation span').html()));

    var data = {
      date: $('.date-confirmation span').html(),
      time: $('.time-confirmation span').html(),
      location: $('.location-confirmation span').html(),
      address: $('input[name="home-addr-line1"]').val(),
      city: $('input[name="home-city"]').val(),
      postcode: $('input[name="home-postcode"]').val(),
      office: $('[name="office-selector"]').val(),
      phone: $('[name="phonenumber"]').val(),
      email: $('[name="emailaddress"]').val(),
      contacted: $('[name="radio-contact-method"]:checked').val()
    };
    return btoa(JSON.stringify(data));
  };

  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : sParameterName[1];
      }
    }
  };

  var formatTimeForSlider = function (input) {
    switch (input) {
      case 800:
        return '08:00 - 10:00';
      case 900:
        return '09:00 - 11:00';
      case 1000:
        return '10:00 - 12:00';
      case 1100:
        return '11:00 - 13:00';
      case 1200:
        return '12:00 - 14:00';
      case 1300:
        return '13:00 - 15:00';
      case 1400:
        return '14:00 - 16:00';
      case 1500:
        return '15:00 - 17:00';
      case 1600:
        return '16:00 - 18:00';
      case 1700:
        return '17:00 - 19:00';
      case 1800:
        return '18:00 - 20:00';
    }
  };

  // -------------------
  var compForm = function () {

    var form = $('.comp-sidebar');

    if (form.length) {

      var question = $('.question', form),
        answer = $('.form-answer input', form);

      question.on('input', function () {
        if (this.value.length > 1) {
          answer.val(this.value);
        }
      });

    }

  };

  // -------------------
  var serviceListMobile = function () {

    var serviceList = $('.service-list-mobile');

    if (serviceList.length) {

      serviceList.on('click', function () {

        $('.services-list').slideToggle();
        $(this).toggleClass('active');

      });

    }

  };

  // -------------------
  var assignURLParameters = function () {
    if (getUrlParameter('utm_source') !== undefined) {
      $('#campaign-source').val(getUrlParameter('utm_source'));
    }
    if (getUrlParameter('utm_medium') !== undefined) {
      $('#campaign-medium').val(getUrlParameter('utm_medium'));
    }
    if (getUrlParameter('utm_campaign') !== undefined) {
      $('#campaign-name').val(getUrlParameter('utm_campaign'));
    }
    if (getUrlParameter('utm_term') !== undefined) {
      $('#campaign-term').val(getUrlParameter('utm_term'));
    }
    if (getUrlParameter('utm_content') !== undefined) {
      $('#campaign-content').val(getUrlParameter('utm_content'));
    }
  };

  var fixSidebar = function () {
    $('ul.services-list').removeClass('bottom').addClass('fixed');
  };
  var fixToBottom = function () {
    $('ul.services-list').addClass('bottom').removeClass('fixed');
  };

  var stickySidebar = function () {
    if ($("ul.services-list").length > 0) {
      var servicesSidebarPosition = $("ul.services-list").offset().top;
      var headerHeight = $('header.primary').outerHeight();
      var listHeight = $('ul.services-list').outerHeight();
      var containerHeight = $('.services-container').height();
      var containerStartPosition = $('.services-container').offset().top;
      $(document).on('scroll', function () {

        if (window.matchMedia("(min-width: 1200px)").matches) {

          var logic = servicesSidebarPosition + containerHeight - listHeight - headerHeight;
          var logic = logic;

          if ($(document).scrollTop() >= logic) {
            fixToBottom();
          } else if (servicesSidebarPosition <= headerHeight) {
            fixSidebar();
          } else if (($(document).scrollTop() + headerHeight) >= servicesSidebarPosition) {
            fixSidebar();
          } else {
            $('ul.services-list').removeClass('bottom').removeClass('fixed');

          }

        }

      });
    }
  };

  // -------------------
  var eventSearchScroll = function () {

    var events = $('.gmw-results-wrapper');

    if (events.length) {

      if (window.matchMedia("(min-width: 992px)").matches) {

        var headerHeight = $('header.primary').height() + 50;

        $('html, body').animate({
          scrollTop: events.offset().top - headerHeight
        }, 1500);

      }

    }

  };

  var setCookie = function (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  var getCookie = function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  var checkCookies = function () {
    if (getCookie('tracking_utm_source') !== undefined) {
      return true;
    } else if (getCookie('tracking_utm_medium') !== undefined) {
      return true;
    } else if (getCookie('tracking_utm_campaign') !== undefined) {
      return true;
    } else if (getCookie('tracking_utm_term') !== undefined) {
      return true;
    } else if (getCookie('tracking_utm_content') !== undefined) {
      return true;
    } else {
      return false;
    }
  };

  var assignCookiesToInputs = function () {
    if (getCookie('tracking_utm_source') !== undefined) {
      $('#campaign-source').val(getCookie('tracking_utm_source'));
    }
    if (getCookie('tracking_utm_medium') !== undefined) {
      $('#campaign-medium').val(getCookie('tracking_utm_medium'));
    }
    if (getCookie('tracking_utm_campaign') !== undefined) {
      $('#campaign-name').val(getCookie('tracking_utm_campaign'));
    }
    if (getCookie('tracking_utm_term') !== undefined) {
      $('#campaign-term').val(getCookie('tracking_utm_term'));
    }
    if (getCookie('tracking_utm_content') !== undefined) {
      $('#campaign-content').val(getCookie('tracking_utm_content'));
    }

  };

  var checkURLForTracking = function () {
    if (getUrlParameter('utm_source') !== undefined) {
      setCookie('tracking_utm_source', getUrlParameter('utm_source'), 7);
    }
    if (getUrlParameter('utm_medium') !== undefined) {
      setCookie('tracking_utm_medium', getUrlParameter('utm_medium'), 7);
    }
    if (getUrlParameter('utm_campaign') !== undefined) {
      setCookie('tracking_utm_campaign', getUrlParameter('utm_campaign'), 7);
    }
    if (getUrlParameter('utm_term') !== undefined) {
      setCookie('tracking_utm_term', getUrlParameter('utm_term'), 7);
    }
    if (getUrlParameter('utm_content') !== undefined) {
      setCookie('tracking_utm_content', getUrlParameter('utm_content'), 7);
    }
  };
  // -------------------

  // Add to localstorage cookie check
  var cookieNotice = function () {

    var notice = $('.cookie-notice'),
      close = $('.cookie-notice .close');

    if (notice.length) {

      var noticeHeight = notice.height(),
        header = $('header.primary'),
        servicesSidebar = $('.services-sidebar');

      $(window).on('DOMContentLoaded resize', function () {

        if (!Cookies.get('cookienotice', 'true')) {
          header.css('top', noticeHeight);

          if (servicesSidebar.length && window.matchMedia("(max-width: 1200px)").matches) {

            $('body').css('padding-top', (header.height() + noticeHeight));
            servicesSidebar.css('top', (header.height() + noticeHeight));

          } else {

            $('body').css('padding-top', (header.height() + noticeHeight));

          }
        }

      });

      if (!Cookies.get('cookienotice', 'true')) {

        notice.show();
        $('body').addClass('has-cookie-notice');

      }

      close.on('click', function () {

        Cookies.set('cookienotice', 'true', {expires: 30});
        notice.hide();

        header.attr('style', '');

        if (servicesSidebar.length && window.matchMedia("(max-width: 1200px)").matches) {

          $('body').attr('style', '');
          servicesSidebar.attr('style', '');

        } else {

          $('body').attr('style', '');

        }

      });

    }

  };

  var slickCallout = function () {
    var $calloutSlider = $('.header--callout');
    var $calloutClose = $('.header--callout__close');
    var $wrap = $('.wrap');

    if (!sessionStorage.getItem('calloutbanner') && $('.header--callout').length) {
      $calloutSlider.css('display', 'block');
      $wrap.addClass('callout-open');
    }

    $calloutClose.on('click', function () {
      sessionStorage.setItem('calloutbanner', false);
      $calloutSlider.remove();
      $wrap.removeClass('callout-open');
    });

    $calloutSlider.on('init', function () {
      // display: block must be set first for Slick width JS, then switched to flex on Slick init.
      if ($calloutSlider.css('display') === 'block') {
        $calloutSlider.css('display', 'flex');
      }
    });

    $calloutSlider.slick({
      autoplay: true,
      infinite: true,
      autoplaySpeed: 4000,
      mobileFirst: true,
      slidesToShow: 1,
      arrows: false,
      dots: true,
      fade: true,
      speed: 1000,
      rows: 0,
      slide: 'div',
      pauseOnHover: false,
      pauseOnFocus: false
    });
  };

  // -------------------
  function viewVacancies() {
    $('a[href="#vacancies"]').on('click', function (e) {
      e.preventDefault();
      $('html, body').animate({
        scrollTop: $('#vacancies').offset().top - 100
      }, 600);
    });

    $('a[href="#career-application"]').on('click', function (e) {
      e.preventDefault();
      $('html, body').animate({
        scrollTop: $('#career-application').offset().top - 100
      }, 600);
    });
  }

  var careerNotification = function () {

    if ($('body').hasClass('tax-career_type') || $('body').hasClass('single-career')) {

      var getLocations = $('#use-locations').html();
      var getTypes = $('#use-types').html();
      var putLocations = $('#populate-locations');
      var putTypes = $('#populate-types');

      putLocations.html(getLocations);
      putTypes.html(getTypes);

      var locations = $('[name="locations"]');
      var types = $('[name="career-types"]');

      $('body').on('change', '[name="notify_career_type[]"]', function (e) {
        var values = $("input[name='notify_career_type[]']:checked").map(
          function () {
            return $(this).val();
          }).get();
        types.val(values.toString());
      });

      $('body').on('change', '[name="notify_location[]"]', function (e) {
        var values = $("input[name='notify_location[]']:checked").map(
          function () {
            return $(this).val();
          }).get();
        locations.val(values.toString());
      });

    }
  };

  // -------------------

  var careerApplication = function () {
    var cvLinks = $('.add-cv-links');
    var cvCheckbox = $('[name="cv-link"]');
    var addAnother = $('.add-another');
    var linkNo = 1;
    var fileUploads = $('.notify-uploads');
    var filename = $('.filename');

    filename.find('.remove').on('click', function (e) {
      e.preventDefault();
      var parent = $(this).parent();
      parent.removeClass('active');
      parent.find('.name').html('');
      $(this).closest('.custom-file').find('input').val('');
    });

    fileUploads.find('input:file').on('change', function (e) {
      var filename = e.target.files[0].name;
      $(this).closest('.custom-file').find('.filename .name').html(filename);
      $(this).closest('.custom-file').find('.filename').addClass('active');
    });

    cvCheckbox.on('click', function (e) {
      if ($(this).is(':checked')) {
        cvLinks.slideDown();
      } else {
        cvLinks.slideUp();
      }
    });

    addAnother.on('click', function (e) {
      e.preventDefault();
      linkNo++;
      var parent = $(this).closest('.add-cv-links');
      parent.find('.form-group')
        .append('<input type="text" name="application_links[]" id="link-' +
          linkNo +
          '" placeholder="Paste your link here">');
    });

    var locations = $('[name="application-location"]');
    var links = $('[name="application-links"]');

    $('body').on('change', '[name="application_links[]"]', function (e) {
      var values = $("input[name='application_links[]']").map(
        function () {
          return $(this).val();
        }).get();
      links.val(values);
    });

    $('body').on('change', '[name="apply_location[]"]', function (e) {
      var values = $("input[name='apply_location[]']:checked").map(
        function () {
          return $(this).val();
        }).get();
      locations.val(values.toString());
    });
  };

  // -------------------

  var holidays = [];

  function getHolidaysAjax() {
    $.ajax({
      url: '/wp-admin/admin-ajax.php?action=getHolidayDates',
      success: function (data) {
        data = JSON.parse(data);
        holidays = data.dates;

        $('#date-selector').datepicker({
          beforeShowDay: disableDates,
          firstDay: 1,
          dateFormat: 'dd-mm-yy',
          minDate: 14,
          onSelect: function (date) {
            $('#dateinput').val(date);
            $('.date-confirmation')
              .find('span')
              .text(date);
            formSerialize();
          }
        });
      }
    });
  }

  function disableDates(date) {
    var dt = $.datepicker.formatDate('mm-dd-yy', date);
    var noWeekend = jQuery.datepicker.noWeekends(date);

    if (noWeekend[0]) {
      return $.inArray(dt, holidays) < 0
        ? [true]
        : [false];
    }

    return noWeekend;
  }

  var arrangeConsultationLoad = function () {
    getHolidaysAjax();

    if (checkCookies() === true) {
      assignCookiesToInputs();
    } else {
      assignURLParameters();
    }

    $("input").on('change', function () {
      formSerialize();
    });
    document.addEventListener('wpcf7mailsent', function (event) {
      location = $('#redirect-url').attr('data-url') + '?data=' + formSerialize();
    }, false);

    $('#date-selector .ui-state-default.ui-state-active').click();

    $('.slider-wrap .slider').slider({
      value: 100,
      min: 800,
      max: 1800,
      step: 100,
      slide: function (event, ui) {
        $("#timeoutput").val(formatTimeForSlider(ui.value));
        $(".timeoutput-view").text(formatTimeForSlider(ui.value));
        $('.time-confirmation').find('span').text(formatTimeForSlider(ui.value));
        formSerialize();
      }
    });
    $("#timeoutput").val('08:00 - 10:00');
    $(".timeoutput-view").text('08:00 - 10:00');
    $('.time-confirmation span').text('08:00 - 10:00');

    $('.input-datepicker-ui').dateDropdowns({
      daySuffixes: false,
      monthFormat: 'short',
      defaultDateFormat: 'dd-mm-yyyy',
      required: true
    });

    $('input[type=radio][name=radio-location]').change(function () {
      switch (this.value) {
        case 'Our Office':
          setLocationOffice();
          clearInputs('N/A');
          break;
        case 'Work':
          setLocationWork();
          clearInputs('');
          break;
        case 'Home':
          setLocationHome();
          clearInputs('');
          break;
      }
    });

    $('select[name=office-selector]').change(function () {
      setLocationOffice();
    });

    $(document).on("click touchstart touchend", ".form-checkbox-wrapper ul li", function () {
      $(this).toggleClass('active');
      var currentSubjects = "";
      $('.form-checkbox-wrapper ul li.active').each(function () {
        currentSubjects += $(this).find('span').html() + ", ";
      });
      $('#advice-requests').val(currentSubjects);
      formSerialize();

    });

    var dataToPassToURL = "?";
    $('.next-step-1').on('click', function () {
      if (checkApplicationStep1()) {

        var location = $('.location-confirmation span').html();
        var date = $('.date-confirmation span').html();
        var time = $('.time-confirmation span').html();

        showNextApplicationStep(2);
      }
    });
    $('.next-step-2').on('click', function () {
      if (checkApplicationStep2()) {
        showNextApplicationStep(3);
      }
    });

    $('.input-checkbox-wrap .phone-input input').prop('checked', true);

  };
  // -------------------

  // Map Locations
  var mapLocationsFilter = function () {

    var mapLocations = $('.location-maps'),
      sidebar = $('.location-sidebar', mapLocations);

    $('.map-link').on('click', function () {

      $('.map-link').each(function () {
        $(this).removeClass('active');
      });
      $(this).addClass('active');

      var mapSelect = $(this).data('map'),
        map = $('.location-map');

      map.each(function () {

        var id = $(this).attr('id');

        if (mapSelect == id) {

          var popup = $(this).find('.map-popup');

          $(this).addClass('active');
          setTimeout(function () {
            popup.addClass('active');
          }, 300);

        } else {

          $(this).removeClass('active');
          $(this).find('.map-popup').removeClass('active');

        }
      });

      // Close dropdown on mobiles
      if (window.matchMedia("(max-width: 1199px)").matches) {

        $('ul', sidebar).slideUp();

      }

    });

    $('.map-link-mobile').on('click', function () {

      $(this).next('ul').slideToggle();
      $(this).toggleClass('active');

    });

  };
  // -------------------

  var videoSection = function () {

    var container = $('.video-container');
    var poster = container.find('.video-overlay');
    var play = container.find('.play-icon');
    var video = $('.video-embed');

    var currentSrc = video.attr('src');

    play.on('click', function (e) {
      e.preventDefault();
      e.stopPropagation();
      video.attr('src', currentSrc + '&autoplay=1');
      $(this).parent().fadeOut();
    });

    $('body').on('click', function () {
      video.attr('src', currentSrc);
      poster.fadeIn();
    });

    video.on('click', function (e) {
      e.stopPropagation();
    });

  };

  var anchorNav = function () {
    var headerHeight = $(".header").height() + $(".master-header").height();
    var elem = $(".js-anchor-nav");
    var elemNav = $(".js-anchor-nav").find(".anchor-nav-menu");

    $(window).bind("load scroll", function () {
      var windowScrollPos = $(window).scrollTop();
      var elemPos = elem.offset().top;
      var elemBurger = $(".js-anchor-nav").find(".anchor-nav-burger");

      if (windowScrollPos > (elemPos - headerHeight)) {
        elemBurger.addClass("fixed");
        elemNav.addClass("fixed");
      } else {
        elemBurger.removeClass("fixed");
        elemNav.removeClass("fixed");
      }
    });

    $(".js-burger").on("click", function () {

      if (elem.hasClass("active")) {
        elem.removeClass("active");
      } else {
        elem.addClass("active");
      }
    });

    $(window).bind("load resize", function () {
      var windowHeight = $(window).height() - headerHeight;
      var navHeight = elemNav.height();

      if (windowHeight < navHeight) {
        elem.find(".inner").height(windowHeight);
        elemNav.addClass("scroll");
      } else {
        elem.find(".inner").height("auto");
        elemNav.removeClass("scroll");
      }
    });
  };

  var linkAnchor = function () {

    $(".js-anchor-init").on("click", function (e) {
      e.preventDefault();
      var itemIndex = $(this).data().index;
      $(".js-anchor-nav").removeClass("active");
      $.each($(".anchor-target"), function (index) {

        if (index == itemIndex) {
          var elemOffset = $(this).offset().top;
          $("html, body").animate({scrollTop: elemOffset + "px"});
        }
      });
    });
  };

  var mapDrag = function () {
    $(".map-iframe").bind("mousedown touchstart", function () {
      $(".map-popup").addClass("map-drag");
    });

    $(".map-iframe").bind("mouseup touchend mouseleave", function () {
      $(".map-popup").removeClass("map-drag");
    });
  };

  // -------------------

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        if ($('.js-anchor-nav').length > 0) {
          anchorNav();
        }
        mapDrag();
        heroBgLoad();
        navigation();
        blockService();
        slickEvents();
        slickNews();
        slickNewsInsights();
        slickLocations();
        slickPortraitThumbs();
        heroVideo();
        aos();
        countUp();
        advisorsProfile();
        advisorsList();
        slickTestimonial();
        videoEmbedPlay();
        serviceListMobile();
        advisorsListMobile();
        checkURLForTracking();
        cookieNotice();
        locationsFilter();
        mapLocationsFilter();
        viewVacancies();
        careerNotification();
        careerApplication();
        videoSection();
        linkAnchor();
        portfolios();
        slickPortfolio();
        slickTeam();
        slickCallout();
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'home': {
      init: function () {
        heroPersonaSelect();
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    },
    'single_events': {
      init: function () {
        compForm();
      }
    },
    'page_template_events_search': {
      init: function () {
        eventSearchScroll();
      }
    },
    'page_template_cdv_services_inner_child': {
      init: function () {
        stickySidebar();
      }
    },
    'page_template_cdv_services_index': {
      init: function () {
        stickySidebar();
      }
    },
    'page_template_cdv_appointment': {
      init: function () {
        $('.appointment-page-wrapper.step-1.active button').on('click', function () {
          $('.appointment-page-wrapper.step-2').addClass('active');
          $('html, body').animate({
            scrollTop: $('.appointment-page-wrapper.step-2').offset().top
          }, 1500);
        });
      }
    },
    'page_template_cdv_arrangetalk': {
      init: function () {

        $('.form-checkbox-wrapper ul li').on('click', function () {
          $(this).toggleClass('active');
          var currentSubjects = "";
          $('.form-checkbox-wrapper ul li.active').each(function () {
            currentSubjects += $(this).find('span').html() + ", ";
          });
          $('#subject-input').val(currentSubjects);
        });

        document.addEventListener('wpcf7mailsent', function (event) {
          location = $('#redirect-url').attr('data-url');
        }, false);

      }
    },
    'page_template_cdv_nonmember': {
      init: function () {

        arrangeConsultationLoad();

      }
    },
    'page_template_cdv_arrangeconsultation_contact': {
      init: function () {
        arrangeConsultationLoad();
      }
    },
    'page_template_cdv_thankyou_appointment': {
      init: function () {

        var data = JSON.parse(atob(getUrlParameter('data')));

        $('#date').text(data.date);
        $('#time').text(data.time);
        $('#location').text(data.location);
        $('#address_1').text(data.address);
        $('#city').text(data.city);
        $('#postcode').text(data.postcode);
        $('#office').text(data.office);

        if (data.address !== 'N/A') {
          $('#office-wrapper').hide();
        } else {
          $('#addr-wrapper').hide();
          $('#city-wrapper').hide();
          $('#post-wrapper').hide();
        }
      }
    },
    'page_template_cdv_thankyou_appointment_contact': {
      init: function () {

        var data = JSON.parse(atob(getUrlParameter('data')));

        $('#time').text(data.time);
        $('#postcode').text(data.postcode);
        $('#phone').text(data.phone);
        $('#contacted').text(data.contacted);
        $('#email').text(data.email);

      }
    },
    'page_template_cdv_arrangeconsultation': {
      init: function () {
        arrangeConsultationLoad();
      }
    },
    'page_template_cdv_contact': {
      init: function () {
        $('.slider-wrap .slider').slider({
          value: 100,
          min: 800,
          max: 1800,
          step: 100,
          slide: function (event, ui) {
            $("#timeoutput").val(formatTimeForSlider(ui.value));
            $(".timeoutput-view").text(formatTimeForSlider(ui.value));
            $('.time-confirmation').find('span').text(formatTimeForSlider(ui.value));
            formSerialize();
          }
        });
        $("#timeoutput").val('08:00 - 10:00');
        $('.time-confirmation span').text('08:00 - 10:00');

        $('.input-checkbox-wrap .phone-input input').prop('checked', true);
      }
    }
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };
  $(document).ready(UTIL.loadEvents);
})
(jQuery); // Fully reference jQuery after this point.

document.addEventListener('wpcf7mailsent', function (event) {
  if (event.detail.contactFormId ==
    2194 ||
    event.detail.contactFormId ==
    2034 ||
    event.detail.contactFormId ==
    2195 ||
    event.detail.contactFormId ==
    2644) {
    setTimeout(function () {
      window.location.href = document.getElementById('redirect-url').getAttribute('data-url');
    }, 1000);
  }
});
